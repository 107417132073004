.vercel [cmdk-root] {
  border-radius: 0;
  padding: 0;
}
.vercel.empty-input [cmdk-input] {
  margin-bottom: 0px;
  border-bottom: 0px;
}
.vercel.empty-input [cmdk-root] {
  border: none;
}

.vercel {
  --app-bg: var(--gray1);
  --cmdk-shadow: 0 16px 70px rgb(0 0 0 / 20%);
  --lowContrast: #ffffff;
  --highContrast: #000000;
  --gray1: hsl(0, 0%, 99%);
  --gray2: hsl(0, 0%, 97.3%);
  --gray3: hsl(0, 0%, 95.1%);
  --gray4: hsl(0, 0%, 93%);
  --gray5: hsl(0, 0%, 90.9%);
  --gray6: hsl(0, 0%, 88.7%);
  --gray7: hsl(0, 0%, 85.8%);
  --gray8: hsl(0, 0%, 78%);
  --gray9: hsl(0, 0%, 56.1%);
  --gray10: hsl(0, 0%, 52.3%);
  --gray11: hsl(0, 0%, 43.5%);
  --gray12: hsl(0, 0%, 9%);
  --grayA1: hsla(0, 0%, 0%, 0.012);
  --grayA2: hsla(0, 0%, 0%, 0.027);
  --grayA3: hsla(0, 0%, 0%, 0.047);
  --grayA4: hsla(0, 0%, 0%, 0.071);
  --grayA5: hsla(0, 0%, 0%, 0.09);
  --grayA6: hsla(0, 0%, 0%, 0.114);
  --grayA7: hsla(0, 0%, 0%, 0.141);
  --grayA8: hsla(0, 0%, 0%, 0.22);
  --grayA9: hsla(0, 0%, 0%, 0.439);
  --grayA10: hsla(0, 0%, 0%, 0.478);
  --grayA11: hsla(0, 0%, 0%, 0.565);
  --grayA12: hsla(0, 0%, 0%, 0.91);
  --blue1: hsl(206, 100%, 99.2%);
  --blue2: hsl(210, 100%, 98%);
  --blue3: hsl(209, 100%, 96.5%);
  --blue4: hsl(210, 98.8%, 94%);
  --blue5: hsl(209, 95%, 90.1%);
  --blue6: hsl(209, 81.2%, 84.5%);
  --blue7: hsl(208, 77.5%, 76.9%);
  --blue8: hsl(206, 81.9%, 65.3%);
  --blue9: hsl(206, 100%, 50%);
  --blue10: hsl(208, 100%, 47.3%);
  --blue11: hsl(211, 100%, 43.2%);
  --blue12: hsl(211, 100%, 15%);
}

.dark {
  --app-bg: var(--gray1);
  --lowContrast: #000000;
  --highContrast: #ffffff;
  --gray1: hsl(0, 0%, 8.5%);
  --gray2: hsl(0, 0%, 11%);
  --gray3: hsl(0, 0%, 13.6%);
  --gray4: hsl(0, 0%, 15.8%);
  --gray5: hsl(0, 0%, 17.9%);
  --gray6: hsl(0, 0%, 20.5%);
  --gray7: hsl(0, 0%, 24.3%);
  --gray8: hsl(0, 0%, 31.2%);
  --gray9: hsl(0, 0%, 43.9%);
  --gray10: hsl(0, 0%, 49.4%);
  --gray11: hsl(0, 0%, 62.8%);
  --gray12: hsl(0, 0%, 93%);
  --grayA1: hsla(0, 0%, 100%, 0);
  --grayA2: hsla(0, 0%, 100%, 0.026);
  --grayA3: hsla(0, 0%, 100%, 0.056);
  --grayA4: hsla(0, 0%, 100%, 0.077);
  --grayA5: hsla(0, 0%, 100%, 0.103);
  --grayA6: hsla(0, 0%, 100%, 0.129);
  --grayA7: hsla(0, 0%, 100%, 0.172);
  --grayA8: hsla(0, 0%, 100%, 0.249);
  --grayA9: hsla(0, 0%, 100%, 0.386);
  --grayA10: hsla(0, 0%, 100%, 0.446);
  --grayA11: hsla(0, 0%, 100%, 0.592);
  --grayA12: hsla(0, 0%, 100%, 0.923);
  --blue1: hsl(212, 35%, 9.2%);
  --blue2: hsl(216, 50%, 11.8%);
  --blue3: hsl(214, 59.4%, 15.3%);
  --blue4: hsl(214, 65.8%, 17.9%);
  --blue5: hsl(213, 71.2%, 20.2%);
  --blue6: hsl(212, 77.4%, 23.1%);
  --blue7: hsl(211, 85.1%, 27.4%);
  --blue8: hsl(211, 89.7%, 34.1%);
  --blue9: hsl(206, 100%, 50%);
  --blue10: hsl(209, 100%, 60.6%);
  --blue11: hsl(210, 100%, 66.1%);
  --blue12: hsl(206, 98%, 95.8%);
}

.main {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 120px 24px 160px 24px;
}
.main:before {
  background: radial-gradient(circle, rgba(2, 0, 36, 0) 0, var(--gray1) 100%);
  position: absolute;
  content: '';
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
}
.main:after {
  content: '';
  /* background-image: url('/grid.svg'); */
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.2;
  filter: invert(1);
}
@media (prefers-color-scheme: dark) {
  .main:after {
    filter: unset;
  }
}
.main h1 {
  font-size: 32px;
  color: var(--gray12);
  font-weight: 600;
  letter-spacing: -2px;
  line-height: 40px;
}
.main p {
  color: var(--gray11);
  margin-top: 8px;
  font-size: 16px;
}

.content {
  height: fit-content;
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 640px;
}
.content:after {
  background-image: radial-gradient(at 27% 37%, hsl(215deg, 98%, 61%) 0px, transparent 50%),
    radial-gradient(at 97% 21%, hsl(256deg, 98%, 72%) 0px, transparent 50%),
    radial-gradient(at 52% 99%, hsl(354deg, 98%, 61%) 0px, transparent 50%),
    radial-gradient(at 10% 29%, hsl(133deg, 96%, 67%) 0px, transparent 50%),
    radial-gradient(at 97% 96%, hsl(38deg, 60%, 74%) 0px, transparent 50%),
    radial-gradient(at 33% 50%, hsl(222deg, 67%, 73%) 0px, transparent 50%),
    radial-gradient(at 79% 53%, hsl(343deg, 68%, 79%) 0px, transparent 50%);
  position: absolute;
  content: '';
  z-index: 2;
  width: 100%;
  height: 100%;
  filter: blur(100px) saturate(150%);
  z-index: -1;
  top: 80px;
  opacity: 0.2;
  transform: translateZ(0);
}
@media (prefers-color-scheme: dark) {
  .content:after {
    opacity: 0.1;
  }
}

.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  flex-wrap: wrap;
  gap: 16px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
  transform: translateY(12px);
}

.githubButton,
.installButton,
.switcher button {
  height: 40px;
  color: var(--gray12);
  border-radius: 9999px;
  font-size: 14px;
  transition-duration: 150ms;
  transition-property: background, color, transform;
  transition-timing-function: ease-in;
  will-change: transform;
}

.githubButton {
  width: 177px;
  padding: 0 12px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}
.githubButton:hover {
  background: var(--grayA3);
}
.githubButton:active {
  background: var(--grayA5);
  transform: scale(0.97);
}
.githubButton:focus-visible {
  outline: 0;
  outline: 2px solid var(--gray7);
}

.installButton {
  background: var(--grayA3);
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0px 8px 0 16px;
  cursor: copy;
  font-weight: 500;
}
.installButton:hover {
  background: var(--grayA4);
}
.installButton:hover span {
  background: var(--grayA5);
}
.installButton:hover span svg {
  color: var(--gray12);
}
.installButton:focus-visible {
  outline: 0;
  outline: 2px solid var(--gray7);
  outline-offset: 2px;
}
.installButton:active {
  background: var(--gray5);
  transform: scale(0.97);
}
.installButton span {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background: var(--grayA3);
  border-radius: 9999px;
  transition: background 150ms ease;
}
.installButton span svg {
  size: 16px;
  color: var(--gray11);
  transition: color 150ms ease;
}

.switcher {
  display: grid;
  grid-template-columns: repeat(4, 100px);
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 48px;
  position: relative;
}
.switcher button {
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  margin: auto;
  gap: 8px;
  padding: 0 16px;
  border-radius: 9999px;
  color: var(--gray11);
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  position: relative;
  text-transform: capitalize;
}
.switcher button:hover {
  color: var(--gray12);
}
.switcher button:active {
  transform: scale(0.96);
}
.switcher button:focus-visible {
  outline: 0;
  outline: 2px solid var(--gray7);
}
.switcher button svg {
  width: 14px;
  height: 14px;
}
.switcher button[data-selected='true'] {
  color: var(--gray12);
}
.switcher button[data-selected='true']:hover .activeTheme {
  background: var(--grayA6);
}
.switcher button[data-selected='true']:active {
  transform: scale(0.96);
}
.switcher button[data-selected='true']:active .activeTheme {
  background: var(--grayA7);
}
.switcher .activeTheme {
  background: var(--grayA5);
  border-radius: 9999px;
  height: 32px;
  width: 100%;
  top: 0;
  position: absolute;
  left: 0;
}
.switcher .arrow {
  color: var(--gray11);
  user-select: none;
  position: absolute;
}

.header {
  position: absolute;
  left: 0;
  top: -64px;
  gap: 8px;
  background: var(--gray3);
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 9999px;
}
.header button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 4px;
  border-radius: 9999px;
  color: var(--gray11);
}
.header button svg {
  width: 16px;
  height: 16px;
}
.header button[aria-selected='true'] {
  background: #ffffff;
  color: var(--gray12);
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.15), 0 1px 3px -1px rgba(0, 0, 0, 0.2);
}

.versionBadge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--grayA11);
  background: var(--grayA3);
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
}
@media (prefers-color-scheme: dark) {
  .versionBadge {
    background: var(--grayA2);
  }
}

.codeBlock {
  margin-top: 72px;
  position: relative;
}

.footer {
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  margin: 32px auto;
  bottom: 16px;
  color: var(--gray11);
  font-size: 13px;
  z-index: 3;
  position: absolute;
  bottom: 0;
}
.footer a {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: var(--gray12);
  font-weight: 500;
  border-radius: 9999px;
  padding: 4px;
  margin: 0 -2px;
  transition: background 150ms ease;
}
.footer a:hover,
.footer a:focus-visible {
  background: var(--grayA4);
  outline: 0;
}
.footer img {
  width: 20px;
  height: 20px;
  border: 1px solid var(--gray5);
  border-radius: 9999px;
}

.line {
  height: 20px;
  width: 180px;
  margin: 64px auto;
  /* background-image: url('/line.svg'); */
  filter: invert(1);
  mask-image: linear-gradient(90deg, transparent, #fff 4rem, #fff calc(100% - 4rem), transparent);
}
@media (prefers-color-scheme: dark) {
  .line {
    filter: unset;
  }
}

.line2 {
  height: 1px;
  width: 300px;
  background: var(--gray7);
  position: absolute;
  top: 0;
  mask-image: linear-gradient(90deg, transparent, #fff 4rem, #fff calc(100% - 4rem), transparent);
}

.line3 {
  height: 300px;
  width: calc(100% + 32px);
  position: absolute;
  top: -16px;
  left: -16px;
  border-radius: 16px 16px 0 0;
  --size: 1px;
  --gradient: linear-gradient(to top, var(--gray1), var(--gray7));
}
.line3::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: var(--size);
  background: linear-gradient(to top, var(--gray1), var(--gray7));
  mask: linear-gradient(black, black) content-box, linear-gradient(black, black);
  mask-composite: exclude;
  transform: translateZ(0);
}
@media (prefers-color-scheme: dark) {
  .line3::before {
    mask: none;
    mask-composite: none;
    opacity: 0.2;
    backdrop-filter: blur(20px);
  }
}

.raunoSignature,
.pacoSignature {
  position: absolute;
  height: fit-content;
  color: var(--gray11);
  pointer-events: none;
}

.raunoSignature {
  width: 120px;
  stroke-dashoffset: 1;
  stroke-dasharray: 1;
  right: -48px;
}

.pacoSignature {
  width: 120px;
  stroke-dashoffset: 1;
  stroke-dasharray: 1;
  left: -8px;
}

.footerText {
  display: flex;
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0;
}

.footer[data-animate='true'] .raunoSignature path {
  animation: drawRaunoSignature 1.5s ease forwards 0.5s;
}
.footer[data-animate='true'] .pacoSignature path {
  animation: drawPacoSignature 0.8s linear forwards 0.5s;
}
.footer[data-animate='true'] .footerText {
  animation: showFooter 1s linear forwards 3s;
}

@keyframes drawPacoSignature {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes drawRaunoSignature {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes showFooter {
  100% {
    opacity: 1;
  }
}
@media (max-width: 640px) {
  .main {
    padding-top: 24px;
    padding-bottom: 120px;
  }
  .switcher {
    grid-template-columns: repeat(2, 100px);
    gap: 16px;
  }
  .switcher .arrow {
    display: none;
  }
}
.vercel [cmdk-root] {
  max-width: 640px;
  width: 100%;
  background: transparent;
  border-radius: 0px;
  overflow: hidden;
  transition: transform 100ms ease;
  outline: none;
}
.dark .vercel [cmdk-root] {
  background: rgba(22, 22, 22, 0.7);
}
.vercel [cmdk-input] {
  font-family: var(--font-sans);
  border: none;
  width: 100%;
  font-size: 17px;
  padding: 8px;
  outline: none;
  background: var(--bg);
  color: var(--gray12);
  border-bottom: 1px solid var(--gray6);
  /* margin-bottom: 16px; */
  border-radius: 0;
  height: 40px;
  font-size: 14px;
}
.vercel [cmdk-input]::placeholder {
  color: var(--gray9);
}
.vercel [cmdk-vercel-badge] {
  height: 20px;
  background: var(--grayA3);
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  font-size: 12px;
  color: var(--grayA11);
  border-radius: 4px;
  margin: 4px 0 4px 4px;
  user-select: none;
  text-transform: capitalize;
  font-weight: 500;
}
.vercel [cmdk-item] {
  content-visibility: auto;
  cursor: pointer;
  height: 'auto';
  /* border-radius: 8px; */
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  color: var(--gray11);
  user-select: none;
  will-change: background, color;
  transition: all 150ms ease;
  transition-property: none;
  border-bottom: 1px solid #f5f5f5;
}
.vercel [cmdk-item][data-selected='true'] {
  background: var(--grayA3);
  color: var(--gray12);
}
.vercel [cmdk-item][data-disabled='true'] {
  color: var(--gray8);
  cursor: not-allowed;
}
.vercel [cmdk-item]:active {
  transition-property: background;
  background: var(--gray4);
}
.vercel [cmdk-item] + [cmdk-item] {
  /* margin-top: 4px; */
}
.vercel [cmdk-item] svg {
  width: 18px;
  height: 18px;
}
.vercel [cmdk-list] {
  /* height: min(330px, var(--cmdk-list-height));
  max-height: 400px; */
  overflow: auto;
  overscroll-behavior: contain;
  transition: 100ms ease;
  transition-property: height;
  background: #fff;
  border-radius: 4px;
  margin-top: 3px;
  max-height: 80vh;
}

.vercel [cmdk-vercel-shortcuts] {
  display: flex;
  margin-left: auto;
  gap: 8px;
}
.vercel [cmdk-vercel-shortcuts] kbd {
  font-family: var(--font-sans);
  font-size: 12px;
  min-width: 20px;
  padding: 4px;
  height: 20px;
  border-radius: 4px;
  color: var(--gray11);
  background: var(--gray4);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.vercel [cmdk-separator] {
  height: 1px;
  width: 100%;
  background: var(--gray5);
  margin: 4px 0;
}
.vercel *:not([hidden]) + [cmdk-group] {
  margin-top: 8px;
}
.vercel [cmdk-group-heading] {
  user-select: none;
  font-size: 12px;
  color: var(--gray11);
  padding: 0 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #141414;
  padding: 16px;
}
.vercel [cmdk-empty] {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  white-space: pre-wrap;
  color: var(--gray11);
}
