.swiper {
  width: 688px;
  height: 688px;
  flex-shrink: 0;
  border-radius: 9.282px;
  border: 1.16px solid #d6d6d6;
  background: #fff;
}

@media only screen and (max-width: 900px) {
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide img {
    width: 100%;
    height: 'auto';
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: contain;
  user-select: none;
  max-width: 100%;
  max-height: 100%;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  color: #737373;
  font-size: 18px;
  font-weight: 600;
}
.swiper-button-prev,
.swiper-button-next {
  display: flex;
  width: 53px;
  height: 53px;
  padding: 8.833px;
  justify-content: center;
  align-items: center;
  border-radius: 8.833px;
  border: 2.208px solid #d6d6d6;
  background: #fff;
  box-shadow: 0px 2.20833px 0px 0px rgba(0, 0, 0, 0.05);
}

#react-portal-wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 9999;
  align-items: center;
  background: rgba(16, 17, 19, 0.2);
}
