/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #b8b7b8;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.MuiDataGrid-paper {
  max-height: unset;
}

.MuiDataGrid-filterForm .MuiChip-avatar {
  display: none;
}

.MuiDataGrid-filterForm .MuiDataGrid-filterFormDeleteIcon {
  min-width: 90px;
}

.MuiDataGrid-filterForm .MuiDataGrid-filterFormLogicOperatorInput {
  min-width: 90px;
  display: none;
}
.MuiDataGrid-filterForm .MuiDataGrid-filterFormColumnInput {
  min-width: 200px;
}
.MuiDataGrid-filterForm .MuiDataGrid-filterFormOperatorInput {
  min-width: 200px;
}

.MuiBox-root .MuiDataGrid-columnHeader--filledGroup {
  display: none;
}

.MuiDataGrid-panel .MuiPaper-elevation {
  margin-top: -110px;
}

.globalOutlinedInput {
  /* Root outline */
  .MuiOutlinedInput-root {
    outline: 1px solid #d6d6d6;
  }

  /* Remove border on notched outline */
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  /* Remove border on hover */
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  /* Focused outline */
  .MuiOutlinedInput-root.Mui-focused {
    border: none;
    outline: 1px solid #141414;
  }

  /* Placeholder style */
  .MuiInputBase-input::placeholder {
    color: #737373;
    font-size: 0.875rem; /* Equivalent to remCalc(14) */
  }
}
